import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'log-in',
        path: `${AUTH_PREFIX_PATH}/log-in`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboards.default.app',
        path: `${APP_PREFIX_PATH}/app/panel/default`,
        component: React.lazy(() => import('views/app-views/dashboards/dashboard')),
    },
    {
        key: 'dashboards.default',
        path: `${APP_PREFIX_PATH}/`,
        component: React.lazy(() => import('views/app-views/dashboards/dashboard')),
    },
    {
        key: 'dashboards.default.s',
        path: `${APP_PREFIX_PATH}/dashboard`,
        component: React.lazy(() => import('views/app-views/dashboards/dashboard')),
    },
    {
        key: 'pages.doctor-list',
        path: `${APP_PREFIX_PATH}/doctores`,
        component: React.lazy(() => import('views/app-views/doctores')),
    },
    {
        key: 'pages.doctor-new',
        path: `${APP_PREFIX_PATH}/doctores/nuevo`,
        component: React.lazy(() => import('views/app-views/doctores/form')),
    },
    {
        key: 'pages.doctor-edit',
        path: `${APP_PREFIX_PATH}/doctores/editar/:id`,
        component: React.lazy(() => import('views/app-views/doctores/edit')),
    },
    
    {
        key: 'pages.perfil',
        path: `${APP_PREFIX_PATH}/perfil`,
        component: React.lazy(() => import('views/app-views/perfil')),
    },
    {
        key: 'pages.perfil.id',
        path: `${APP_PREFIX_PATH}/perfil/:id`,
        component: React.lazy(() => import('views/app-views/perfil/edit')),
    },
    {
        key: 'pages.pacientes-list',
        path: `${APP_PREFIX_PATH}/pacientes`,
        component: React.lazy(() => import('views/app-views/pacientes')),
    },
    {
        key: 'pages.pacientes-list-poliza',
        path: `${APP_PREFIX_PATH}/pacientes/:id`,
        component: React.lazy(() => import('views/app-views/pacientes')),
    },
    {
        key: 'pages.pacientes-new',
        path: `${APP_PREFIX_PATH}/pacientes/nuevo`,
        component: React.lazy(() => import('views/app-views/pacientes/form')),
    },
    {
        key: 'pages.pacientes-new-poliza',
        path: `${APP_PREFIX_PATH}/pacientes/nuevo/:poliza_id`,
        component: React.lazy(() => import('views/app-views/pacientes/form')),
    },
    {
        key: 'pages.pacientes-edit',
        path: `${APP_PREFIX_PATH}/pacientes/editar/:id`,
        component: React.lazy(() => import('views/app-views/pacientes/edit')),
    },
    {
        key: 'pages.pacientes-detalles',
        path: `${APP_PREFIX_PATH}/pacientes/ver/:id`,
        component: React.lazy(() => import('views/app-views/pacientes/detalles')),
    },
    {
        key: 'pages.titulares-list',
        path: `${APP_PREFIX_PATH}/titulares`,
        component: React.lazy(() => import('views/app-views/titulares')),
    },
    {
        key: 'pages.titulares-new',
        path: `${APP_PREFIX_PATH}/titulares/nuevo`,
        component: React.lazy(() => import('views/app-views/titulares/form')),
    },
    {
        key: 'pages.titulares-edit',
        path: `${APP_PREFIX_PATH}/titulares/editar/:id`,
        component: React.lazy(() => import('views/app-views/titulares/edit')),
    },
    {
        key: 'pages.verificaciones-list',
        path: `${APP_PREFIX_PATH}/verificaciones/nuevas`,
        component: React.lazy(() => import('views/app-views/verificaciones/nuevas')),
    },
    {
        key: 'pages.verificaciones-list',
        path: `${APP_PREFIX_PATH}/verificaciones/existentes`,
        component: React.lazy(() => import('views/app-views/verificaciones/existentes')),
    },
    {
        key: 'pages.verificaciones-form',
        path: `${APP_PREFIX_PATH}/verificaciones/nueva`,
        component: React.lazy(() => import('views/app-views/verificaciones/form')),
    },
    {
        key: 'pages.verificaciones-edit',
        path: `${APP_PREFIX_PATH}/verificaciones/editar/:id`,
        component: React.lazy(() => import('views/app-views/verificaciones/edit')),
    },
    {
        key: 'pages.reembolsos-list',
        path: `${APP_PREFIX_PATH}/reembolsos`,
        component: React.lazy(() => import('views/app-views/reembolsos')),
    },
    {
        key: 'pages.reembolsos-form',
        path: `${APP_PREFIX_PATH}/reembolsos/nuevo/:verificacion_id`,
        component: React.lazy(() => import('views/app-views/reembolsos/form')),
    },
    {
        key: 'pages.reembolsos-edit',
        path: `${APP_PREFIX_PATH}/reembolsos/editar/:verificacion_id/:id`,
        component: React.lazy(() => import('views/app-views/reembolsos/edit')),
    },
    {
        key: 'pages.descargas-list',
        path: `${APP_PREFIX_PATH}/descargas`,
        component: React.lazy(() => import('views/app-views/descargas')),
    },
    {
        key: 'pages.notas-list',
        path: `${APP_PREFIX_PATH}/notas`,
        component: React.lazy(() => import('views/app-views/notas')),
    },
    {
        key: 'pages.mensajes-list',
        path: `${APP_PREFIX_PATH}/mensajes`,
        component: React.lazy(() => import('views/app-views/mensajes')),
    },
    {
        key: 'pages.usuarios-list',
        path: `${APP_PREFIX_PATH}/usuarios`,
        component: React.lazy(() => import('views/app-views/usuarios')),
    },
    {
        key: 'pages.usuarios-new',
        path: `${APP_PREFIX_PATH}/usuarios/nuevo`,
        component: React.lazy(() => import('views/app-views/usuarios/user-form')),
    },
    {
        key: 'pages.usuarios-edit',
        path: `${APP_PREFIX_PATH}/usuarios/editar/:id`,
        component: React.lazy(() => import('views/app-views/usuarios/user-edit')),
    },
    {
        key: 'pages.roles-list',
        path: `${APP_PREFIX_PATH}/roles`,
        component: React.lazy(() => import('views/app-views/roles')),
    },
    {
        key: 'pages.roles-edit',
        path: `${APP_PREFIX_PATH}/roles/editar/:id`,
        component: React.lazy(() => import('views/app-views/roles/edit')),
    },
    {
        key: 'pages.descargas-list',
        path: `${APP_PREFIX_PATH}/descargas`,
        component: React.lazy(() => import('views/app-views/descargas')),
    },
    {
        key: 'pages.descargas-new',
        path: `${APP_PREFIX_PATH}/descargas/nuevo`,
        component: React.lazy(() => import('views/app-views/descargas/form')),
    },
    {
        key: 'pages.aseguradoras-list',
        path: `${APP_PREFIX_PATH}/aseguradoras`,
        component: React.lazy(() => import('views/app-views/aseguradoras')),
    },
    {
        key: 'pages.aseguradoras-form',
        path: `${APP_PREFIX_PATH}/aseguradoras/nueva`,
        component: React.lazy(() => import('views/app-views/aseguradoras/form')),
    },
    {
        key: 'pages.aseguradoras-edit',
        path: `${APP_PREFIX_PATH}/aseguradoras/editar/:id`,
        component: React.lazy(() => import('views/app-views/aseguradoras/edit')),
    },
]