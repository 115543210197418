const dev = {
    API_ENDPOINT_URL: 'http://apiexcellents.local/api',
    ENDPOINT_URL: 'http://apiexcellents.local',
    DOMAIN: 'apiexcellents.local'
  };
  
  /*
  const prod = {
    API_ENDPOINT_URL: 'https://excellensglobal.net/api',
    ENDPOINT_URL: 'https://excellensglobal.net',
    DOMAIN: 'excellensglobal.net'
  };
  */

  const prod = {
    API_ENDPOINT_URL: 'https://api.excellensglobal.net/api',
    ENDPOINT_URL: 'https://api.excellensglobal.net',
    DOMAIN: 'api.excellensglobal.net'
  };
  
  const test = {
    API_ENDPOINT_URL: 'https://api.excellensglobal.net/api'
  };
  
  const getEnv = () => {
      switch (process.env.NODE_ENV) {
          case 'development':
              return dev
          case 'production':
              return prod
          case 'test':
              return test
          default:
              return prod
              break;
      }
  }
  
  export const env = getEnv()